export const providerTypes = {
	amendmentMainSubject: 'amendmentMainSubject',
	rechtsvormen: 'rechtsvormen',
	article: 'article',
	cbsFactsheet: 'cbsFactsheet',
	factsheet: 'factsheet',
	coenOverview: 'coenOverview',
	contactForm: 'contactForm',
	contactOverview: 'contactOverview',
	detacheren: 'detacheren',
	eventDetail: 'eventDetail',
	eventOverview: 'eventOverview',
	factsandfiguresOverview: 'factsandfiguresOverview',
	homepage: 'homepage',
	landenDetail: 'countryInfo',
	landenOverzicht: 'landenOverzicht',
	menu: 'menu',
	prinsjesdag: 'prinsjesdag',
	regelhulpenOverzicht: 'regelhulpenOverzicht',
	regioDetail: 'regioDetail',
	regioOverzicht: 'regioOverzicht',
	sdgArticle: 'sdgArticle',
	search: 'search',
	sectorDetail: 'sectorDetail',
	sectorOverzicht: 'sectorOverzicht',
	videos: 'videos',
	subsidies: 'subsidies',
	terugbetalen: 'terugbetalen',
	transaction: 'transaction',
	incoterms: 'incoterms',
	cookiePage: 'cookiePage',
	themaMenu: 'themaMenu',
	wasteGuide: 'wasteGuide',
	webinardetail: 'webinardetail',
	amendments: 'amendments',
	regulations: 'regulations',
	regulationSubject: 'regulationSubject',
} as const;
